.contactSection {
  background-color: $greenFive;
  padding-top: 50px;
  padding-bottom: 50px;
  h3 {
    text-align: center;
    color: #ffffff;
    font-family: 'klinic_slablight';
    font-weight: 600;
    font-size: 32px;
  }
  hr {
    width: 20px;
    margin-bottom: 30px;
  }
  p {
    font-family: 'Avenir';
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  #formContainer {
    margin-top: 5px;
    font-family: 'Avenir';
    font-weight: 500;
    #contactButton {
      float: right;
      background-color: $greenThree;
      font-family: 'Avenir';
      font-weight: 500;
      font-size: 16px;
      width: 200px;
      -webkit-transition: all 500ms ease-out;
      -moz-transition: all 500ms ease-out;
      -o-transition: all 500ms ease-out;
      transition: all 500ms ease-out;
      img {
        margin-left: 10px;
        max-width: 20px;
        display: inline;
      }
      &:hover {
        background-color: $greenFour;
      }
    }
    input[type="text"], input[type="password"], input[type="email"], select, textarea {
      -moz-appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      appearance: none;
      border-style: solid;
      border-width: 1px;
      display: block;
      outline: 0;
      padding: 0 1em;
      text-decoration: none;
      width: 100%;
      padding: 10px;
    }
  }
}

.aboveFooter {
  background-color: $black;
  color: #ffffff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  p {
    margin-bottom: 0px;
    font-size: 16px;
    font-family: 'Avenir';
    font-weight: 500;
    display: inline;
    i {
      color: $orange;
    }
    #angiesListLogo {
      height: 20px;
      margin-top: -2px;
    }
  }
}

@media screen and (max-width: 695px) {
  .aboveFooter {
    p {
      display: block;
    }
  }
}

@media screen and (max-width: 639px) {
  .contactSection h3 {
    font-size: 25px;
  }
}
