.image0 {
  background: white url("../img/header1.jpg") center center no-repeat;

  #headTitle {
    display: block !important;
  }
}

.image1 {
  background: white url("../img/header2.jpg") center center no-repeat;
}

.image2 {
  background: white url("../img/header3.jpeg") center center no-repeat;
}

.image3 {
  background: white url("../img/header4.jpg") center center no-repeat;
}

.image4 {
  background: white url("../img/header5.jpg") center center no-repeat;
}

.image5 {
  background: white url("../img/header6.jpg") center center no-repeat;
}

.headerTitle {
  position: relative;
  font-size: 30px;
  margin-bottom: 30px;
  font-family: 'klinic_slabmedium';
  color: #ffffff;
  text-align: center;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 1);
  margin-top: 300px;

  h2 {
    font-family: 'klinic_slabbold';
    font-size: 36px;
  }
}

.headerSection {
  padding-top: 50px;
  z-index: -1;
  width: 100%;
  height: 700px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  background-attachment: fixed;
  top:0;
  left:0;
  background-size: 110%;
  background-position: 0px -100px !important;
}

@media screen and (max-width: 1323px) {
  .image0 {
    background-position: 0px 20px !important;
  }
}

@media screen and (max-width: 1133px) {
  .headerTitle {
    margin-top: 250px;
  }
}

@media screen and (max-width: 1025px) {
  .headerSection {
    background-position: 0px -150px;
  }
}

@media screen and (max-width: 860px) {
  .headerSection {
    position: relative;
    height: 700px;
    background-size: 640px;
    background-position-x: center !important;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
    background-position: 0px 0px !important;
  }
  .headerTitle {
    position: absolute;
    top: 400px;
    text-align: center;
    width: 100%;
    margin-top: 0px;
    h2 {
      font-size: 28px !important;
    }
  }
}

@media screen and (max-width: 640px) {
  .headerSection {
    height: 570px;
    background-size: 640px;
    background-position-x: center !important;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
    background-position: 0px 0px !important;
  }
  .headerTitle {
    position: absolute;
    top: 280px;
    text-align: center;
    width: 100%;
    h2 {
      font-size: 26px !important;
    }
    br {
      display: none;
    }
  }
}
