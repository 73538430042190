.workContent {
  margin-top: 100px;
  h2 {
    text-align: center;
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
  }
  hr {
    width: 20px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .lightboxGrid {
    a {
      position: relative;
      height: 250px;
      width: 32.9%;
      display: inline-block;
      overflow: hidden;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      &:first-of-type {
        height: 500px;
        width: 66.4%;
        float: left;
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  }
}

.sl-close {
  color: $orange !important;
  font-size: 40px;
}

.sl-overlay {
  background-color: black;
  opacity: 0.9;
}

.sl-wrapper .sl-navigation button {
  overflow: visible;
  height: 60px !important;
}

@media screen and (max-width: 1025px) {
  .workContent {
    margin-top: 150px;
  }
}

@media screen and (max-width: 922px) {
  .workContent .lightboxGrid a {
    width: 49% !important;
    &:first-of-type {
      width: 49%;
      height: 250px;
      float: none;
      margin-right: 0px;
      margin-top: 0px;
    }
  }
}

@media screen and (max-width: 780px) {
  .sl-navigation {
    display: none !important;
  }
}

@media screen and (max-width: 590px) {
  .workContent .lightboxGrid a {
    width: 100% !important;
    height: 400px;
    &:first-of-type {
      width: 100%;
      height: 400px;
    }
  }
}

@media screen and (max-width: 590px) {
  .workContent .lightboxGrid a {
    width: 100% !important;
    height: 300px;
    &:first-of-type {
      width: 100%;
      height: 300px;
    }
  }
}

@media screen and (max-width: 639px) {
  .workContent {
    margin-top: 50px;
    .contentSection {
      padding-left: 0px;
      padding-right: 0px;
      .small-12 {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

}
