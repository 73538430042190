.homeContent {
  margin-top: 120px !important;
}
.nonHomeContent {
  margin-top: 150px !important;
}
.mainContent {
  margin-top: 300px;
  background-color: #ffffff;
  .blockTextSection {
    background-color: $greenFive;
    text-align: center;
    color: #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;

    h3 {
      margin-bottom: 0px;
      font-family: 'klinic_slablight';
      font-weight: 600;
      font-size: 32px;
    }

    .sectionMainText {
      font-family: 'Avenir';
      font-weight: 500;
      font-style: italic;
      font-size: 20px;
      padding-left: 205px;
      padding-right: 205px;
    }

    .sectionText {
      font-family: 'Avenir';
      padding-left: 205px;
      padding-right: 205px;
    }

    hr {
      width: 20px;
      margin-bottom: 30px;
    }
  }

  .grid {
    .fullRow {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      max-width: 100%;

      .gridItem {
        display: inline-block;
        width: 50%;
        padding: 50px;
        height: 400px;
        text-align: center;

        .gridIcons {
          max-width: 30px;
          margin-left: auto;
          margin-right: auto;
          &.lightIcon {
            max-width: 20px;
          }
        }

        h3 {
          text-align: center;
          color: $greenThree;
          font-family: 'klinic_slablight';
          font-weight: 600;
          margin-bottom: 0px;
        }

        hr {
          display: block;
          height: 1px;
          border: 0;
          border-top: 1px solid $greenThree;
          margin: 1em 0;
          padding: 0;
          width: 20px;
          margin-left: auto;
          margin-right: auto;
        }

        .itemText {
          font-family: 'Avenir';
          color: $greenTwo;
        }

        .itemLink {
          color: $orange;
          text-align: center;
          font-family: 'Avenir';
          display: block;
        }
      }

      .left {
        float: left;
      }

      .right {
        float: right;
      }

      .gridContentContainer {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        display: inline-block;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
      }

      .imageItem {
      }

      .gridImg1 {
        background: white url("../img/gridBlock/block1.jpg") center center no-repeat;
        background-size: cover;
      }

      .gridImg2 {
        background: white url("../img/gridBlock/block2.jpg") center center no-repeat;
        background-size: cover;
      }

      .gridImg3 {
        background: white url("../img/gridBlock/block3.jpg") center center no-repeat;
        background-size: cover;
      }

      .gridImg4 {
        background: white url("../img/gridBlock/block4.jpeg") center center no-repeat;
        background-size: cover;
      }

    }
  }
}

@media screen and (max-width: 900px) {
  .mainContent {
    margin-top: 150px;
  }
  .sectionText,
  .sectionMainText {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media screen and (max-width: 860px) {
  .mainContent {
    margin-top: 0px !important;
  }
  .gridContentContainer {
    top: 0px;
    transform: initial;
  }
}

@media screen and (max-width: 767px) {
  .mainContent .grid .fullRow .gridItem {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .mainContent {
    margin-top: -40px !important;
    .blockTextSection {
      padding-top: 20px;
    }
  }
  .headerTitle {
    margin-top: 0px;
  }
}

@media screen and (max-width: 375px) {
  .mainContent .grid .fullRow .right,
  .mainContent .grid .fullRow .left {
    height: 550px;
  }
}
