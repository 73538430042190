@font-face {
    font-family: 'klinic_slabbold';
    src: url('../fonts/klinicslabbold-webfont.eot');
    src: url('../fonts/klinicslabbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslabbold-webfont.woff2') format('woff2'),
         url('../fonts/klinicslabbold-webfont.woff') format('woff'),
         url('../fonts/klinicslabbold-webfont.ttf') format('truetype'),
         url('../fonts/klinicslabbold-webfont.svg#klinic_slabbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'klinic_slabbold_italic';
    src: url('../fonts/klinicslabboldit-webfont.eot');
    src: url('../fonts/klinicslabboldit-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslabboldit-webfont.woff2') format('woff2'),
         url('../fonts/klinicslabboldit-webfont.woff') format('woff'),
         url('../fonts/klinicslabboldit-webfont.ttf') format('truetype'),
         url('../fonts/klinicslabboldit-webfont.svg#klinic_slabbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'klinic_slabbook';
    src: url('../fonts/klinicslabbook-webfont.eot');
    src: url('../fonts/klinicslabbook-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslabbook-webfont.woff2') format('woff2'),
         url('../fonts/klinicslabbook-webfont.woff') format('woff'),
         url('../fonts/klinicslabbook-webfont.ttf') format('truetype'),
         url('../fonts/klinicslabbook-webfont.svg#klinic_slabbook') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'klinic_slabbook_italic';
    src: url('../fonts/klinicslabbookit-webfont.eot');
    src: url('../fonts/klinicslabbookit-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslabbookit-webfont.woff2') format('woff2'),
         url('../fonts/klinicslabbookit-webfont.woff') format('woff'),
         url('../fonts/klinicslabbookit-webfont.ttf') format('truetype'),
         url('../fonts/klinicslabbookit-webfont.svg#klinic_slabbook_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'klinic_slablight';
    src: url('../fonts/klinicslablight-webfont.eot');
    src: url('../fonts/klinicslablight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslablight-webfont.woff2') format('woff2'),
         url('../fonts/klinicslablight-webfont.woff') format('woff'),
         url('../fonts/klinicslablight-webfont.ttf') format('truetype'),
         url('../fonts/klinicslablight-webfont.svg#klinic_slablight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'klinic_slablight_italic';
    src: url('../fonts/klinicslablightit-webfont.eot');
    src: url('../fonts/klinicslablightit-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslablightit-webfont.woff2') format('woff2'),
         url('../fonts/klinicslablightit-webfont.woff') format('woff'),
         url('../fonts/klinicslablightit-webfont.ttf') format('truetype'),
         url('../fonts/klinicslablightit-webfont.svg#klinic_slablight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'klinic_slabmedium';
    src: url('../fonts/klinicslabmedium-webfont.eot');
    src: url('../fonts/klinicslabmedium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslabmedium-webfont.woff2') format('woff2'),
         url('../fonts/klinicslabmedium-webfont.woff') format('woff'),
         url('../fonts/klinicslabmedium-webfont.ttf') format('truetype'),
         url('../fonts/klinicslabmedium-webfont.svg#klinic_slabmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'klinic_slabmedium_italic';
    src: url('../fonts/klinicslabmediumit-webfont.eot');
    src: url('../fonts/klinicslabmediumit-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klinicslabmediumit-webfont.woff2') format('woff2'),
         url('../fonts/klinicslabmediumit-webfont.woff') format('woff'),
         url('../fonts/klinicslabmediumit-webfont.ttf') format('truetype'),
         url('../fonts/klinicslabmediumit-webfont.svg#klinic_slabmedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Oblique.eot');
	src: url('../fonts/Avenir-Oblique.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Oblique.woff') format('woff'),
		url('../fonts/Avenir-Oblique.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Roman.eot');
	src: url('../fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Roman.woff') format('woff'),
		url('../fonts/Avenir-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Heavy.eot');
	src: url('../fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Heavy.woff') format('woff'),
		url('../fonts/Avenir-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-MediumOblique.eot');
	src: url('../fonts/Avenir-MediumOblique.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-MediumOblique.woff') format('woff'),
		url('../fonts/Avenir-MediumOblique.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Medium.eot');
	src: url('../fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Medium.woff') format('woff'),
		url('../fonts/Avenir-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-LightOblique.eot');
	src: url('../fonts/Avenir-LightOblique.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-LightOblique.woff') format('woff'),
		url('../fonts/Avenir-LightOblique.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Light.eot');
	src: url('../fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Light.woff') format('woff'),
		url('../fonts/Avenir-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-Black.eot');
	src: url('../fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Black.woff') format('woff'),
		url('../fonts/Avenir-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('../fonts/Avenir-HeavyOblique.eot');
	src: url('../fonts/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-HeavyOblique.woff') format('woff'),
		url('../fonts/Avenir-HeavyOblique.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Black Oblique';
	src: url('../fonts/Avenir-BlackOblique.eot');
	src: url('../fonts/Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-BlackOblique.woff') format('woff'),
		url('../fonts/Avenir-BlackOblique.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Book';
	src: url('../fonts/Avenir-BookOblique.eot');
	src: url('../fonts/Avenir-BookOblique.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-BookOblique.woff') format('woff'),
		url('../fonts/Avenir-BookOblique.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Book';
	src: url('../fonts/Avenir-Book.eot');
	src: url('../fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir-Book.woff') format('woff'),
		url('../fonts/Avenir-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
