body.hidden-scroll {
  overflow: hidden;
}
.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
  display: none;
  z-index: 1006;
}
.sl-wrapper {
  z-index: 1000;
  button {
    border: 0 none;
    background: transparent;
    font-size: 24px;
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  
  .sl-close {
    display: none;
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 1015;
  }
  
  .sl-counter {
    display: none;
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 1015;
  }
  
  .sl-navigation {
    width: 100%;
    display: none;
    button {
      position: fixed;
      top: 50%;
      margin-top: - 10px;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      display: block;
      z-index: 1015;
      font-family: arial sans-serif;
      &.sl-next {
        right: 40px;
      }
      
      &.sl-prev {
        left: 40px;
      }
    }
  }
  
  .sl-image {
    position: fixed;
    -ms-touch-action: none;
    touch-action: none;
    z-index: 10000;
    img {
      margin: 0;
      padding: 0;
      display: block;
    }
    iframe {
      border: 0 none;
      background: #000;
    }
    .sl-caption {
      display: none;
      padding: 10px;
      color: #fff;
      background: #000;
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      
      &.pos-top {
        bottom: auto;
        top: 0;
      }
      
      &.pos-outside {
        bottom: auto;
      }
    }
    
    .sl-download {
      display: none;
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #fff;
      z-index: 1005;
    }
  }
}

.sl-spinner {
    display: none;
    border: 5px solid #333;
    border-radius: 40px;
    height: 40px;
    left: 50%;
    margin: -20px 0 0 -20px;
    opacity: 0;
    position: fixed;
    top: 50%;
    width: 40px;
    z-index: 1007;
    -webkit-animation: pulsate 1s ease-out infinite;
    -moz-animation: pulsate 1s ease-out infinite;
    -ms-animation: pulsate 1s ease-out infinite;
    -o-animation: pulsate 1s ease-out infinite;
    animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@-webkit-keyframes pulsate{
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}
@keyframes pulsate {
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}

@-moz-keyframes pulsate{
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}

@-o-keyframes pulsate{
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}

@-ms-keyframes pulsate{
    0% {
      transform: scale(.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
}