.contentSection {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
  h3 {
    font-family: 'klinic_slablight';
    font-weight: 600;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $greenTwo;
    margin: 1em 0;
    padding: 0;
  }

  .quote-icon {
    color: $orange;
    font-size: 50px;
    padding: 4px;
    margin: 0 7px 2px 0;
    display: inline;
    float: left;
  }

  .sectionMainText {
    font-family: 'Avenir';
    font-weight: 500;
    font-style: italic;
    font-size: 20px;
  }

  .sectionTitle {
    font-family: 'Avenir';
    font-weight: 900;
    margin-bottom: 0px;
    margin-top: 10px;
    color: $black;
  }

  .sectionText {
    font-family: 'Avenir';
    color: $black;
  }

  .workLink {
    border-top: 1px solid $greenTwo;
    text-align: right;
    float: right;
    padding-top: 5px;
    margin-top: 20px;
    color: $orange;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    font-family: 'klinic_slabmedium';
    font-size: 12px;

    &:hover {
      color: $greenTwo;
    }
    .fa-chevron-right {
      margin-left: 10px;
      margin-top: 5px;
    }
  }
}

.sl-wrapper .sl-close {
  font-size: 50px;
  top: 10px;
}

@media screen and (max-width: 1200px) {
  .contentSection {
    padding-left: 25px;
    padding-right: 25px;
  }
}
