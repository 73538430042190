.footerRow {
  background-color: $greenFive;
  height: auto;
  padding-top: 50px;
  margin-bottom: -5px;
  padding-bottom: 50px;
  .footerTitle {
    color: $greenThree;
    font-family: 'Avenir';
    margin-bottom: 5px;
    font-size: 12px;
  }
  ul {
    list-style: none;
    color: #ffffff;
    font-family: 'Avenir';
    font-weight: 500;
    margin-left: 0px;
    .icon {
      display: inline;
      padding-right: 10px;
    }
    .call {
      padding-left: 22px;
    }
    .email {
      padding-left: 10px;
    }
    .footerExtend {
      display: none;
    }
    li {
      margin-bottom: -5px;
    }
    a {
      color: #ffffff;
      font-size: 12px;
      -webkit-transition: all 500ms ease-out;
      -moz-transition: all 500ms ease-out;
      -o-transition: all 500ms ease-out;
      transition: all 500ms ease-out;
      &:hover {
        color: $orange;
      }
    }
  }
}
