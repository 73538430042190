.top-bar {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 -9px 9px 9px rgb(151, 151, 151);
   -moz-box-shadow: 0 -9px 9px 9px rgb(151, 151, 151);
  box-shadow: 0 -9px 9px 9px rgb(151, 151, 151);
  position: fixed;
  width: 100%;
  z-index: 999;
  margin-top: 0px;
  top: 0px;
  padding: 20px !important;
  #mainTitle {
    max-width: 110px;
    margin-bottom: -10px;
    margin-left: 20px;
  }
  .top-bar-right {
    margin-right: 10px;
    li {
      padding-right: 20px;
      .search-term {
        width: 270px;
        height: 35px;
        margin-right: -70px;
        padding-right: 40px !important;
        box-shadow: none;
        font-family: 'Avenir';
        font-size: 12px;
        color: #04615c;
      }
    }
  }
  #rightHandMenu {
    margin-top: 1px;
  }
  ul {
    background-color: #ffffff !important;
    color: black;
    font-family: 'Avenir';
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    a {
      color: #04615c;
      -webkit-transition: all 500ms ease-out;
      -moz-transition: all 500ms ease-out;
      -o-transition: all 500ms ease-out;
      transition: all 500ms ease-out;
      font-size: 14px;
      &:hover {
        color: $orange;
      }
      &.selected {
        color: $orange;
      }
    }
    #callUsAction {
      color: #3bb5a9;
      padding-right: 0px;
      position: absolute;
      right: 20px;
      top: 35px;
      z-index: -1;
      font-family: 'klinic_slabbold';
      font-size: 12px;
      span {
        color: #04615c;
        font-size: 18px;
      }
    }
  }
}

#mobileCallUs {
  display: none;
}

@media screen and (max-width: 1023px) {
  .top-bar-left {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    width: 650px !important;
    float: none !important;
    margin-bottom: -20px;
  }
}

@media screen and (max-width: 639px) {
  .top-bar {
    height: 90px;
    -webkit-box-shadow: 0 -9px 9px 9px rgb(151, 151, 151);
     -moz-box-shadow: 0 -9px 9px 9px rgb(151, 151, 151);
    box-shadow: 0 -9px 9px 9px rgb(151, 151, 151);
    padding-left: 0px !important;
    padding-right: 0px !important;
    .top-bar-title {
      padding-left: 20px;
    }
    .menu-icon.dark {
      margin-top: 5px;
    }
    ul {
      width: 100%;
      margin-top: 0px;
      margin-top: -10px;
      li {
        display: block !important;
      }
    }
  }
  .top-bar-left {
    width: 100% !important;
    margin-top: 30px !important;
    li {
      margin-left: -10px;
    }
    a {
      padding-left: 30px !important;
    }
  }
  .top-bar-right {
    background-color: #ffffff;
    margin-top: -10px;
    margin-right: 0px !important;
    li {
      padding-left: 15px;
    }
  }
  .top-bar ul #callUsAction {
    display: none !important;
  }
  #responsive-menu {

  }
  #mobileCallUs {
    display: block;
    font-family: 'klinic_slabbold';
    font-size: 18px;
    text-align: right;
    padding-right: 20px;
    padding-top: 10px;
    a {
      color: #d3722f;
      padding: 10px;
    }
  }

}
